<template>
  <div :class="{container:true,notScroll:isScroll}">
       <!-- 顶部 -->
    <MoTitle :showLeft="false" title="应用开发服务" @moTitltFn="navPanel"></MoTitle>
    <div class="smartDTContainer">
      <!-- 头部 -->
      <div class="moTitle center">
          <h1 style="color:#fff;font-size: 1.5rem;;margin-bottom:20px">应用开发服务</h1>
          <button class="showBtn" @click="$router.push('/moShow')">立即预约演示</button>
      </div>

      <div class="contentBgColor center">
        <div class="ptDescContent">
          <img src="@/assets/product/dataCenter/dataPt.png" alt="" style="width:100%;border-radius: 10px 10px 0 0 ;">
          <div class="ptDescContentText">
           易普行拥有专业化的应用开发服务团队，长期为客户提供端到端的应用软件开发和维护服务。凭借在CMMI和ISO9001质量标准和流程认证基础上形成的系统化、成熟的方法论，以及多年来的丰富项目实践经验，易普行能够为客户提供包括：系统开发、平台开发、APP开发、项目定制等全栈服务，提升业务效率、大幅的降低开发成本，为客户提供更高的IT投资回报。
          </div>
        </div>
        <h2 class="h2Desc">服务优势</h2>
        <span class="spanDesc"></span>
        <div class="moValueContent">
          <div v-for="(item,index) in valueList" :key="index" class="moValueItem">
            <img :src="item.src" alt="" class="moVlaueImg">
            <div style="margin-left: 20px;">
              <div class="moValueTitle">{{item.title}}</div>
              <div class="moValueText">{{item.content}}</div>
            </div>
          </div>
        </div>
      </div>

    </div>
     <MoBottom></MoBottom>
    <MoFoot></MoFoot>
  </div>
</template>

<script>
import MoTitle from '@/components/Motitle'
export default {
  name: 'moProductYykffw',
  components: {
    MoTitle
  },
  data () {
    return {
      isScroll: false,
      valueList: [
        { title: '全覆盖的信息解决方案', content: '易普行提供高校、医院、研究院等事业单位覆盖全人力资源管理的解决方案。', src: require('@/assets/Cooperation/ecological/open.png'), hoverScr: require('@/assets/product/productPt/jgzx.png') },
        { title: '全链全生命周期开发服务', content: '涵盖开发流程中的各个阶段，包括：需求分析、设计、编码、测试、部署以及实施后的支持。', src: require('@/assets/product/dataCenter/shujufs.png'), hoverScr: require('@/assets/product/dataCenter/sjjc.png') },
        { title: '多样化的定制开发服务', content: '易普行凭借多年的IT行业积累，为客户提供多样化、专业化的定制化开发服务。', src: require('@/assets/product/productPt/agile.png'), hoverScr: require('@/assets/product/productPt/mjbh.png') }
      ]
    }
  },
  methods: {
    navPanel (v) {
      this.isScroll = v
    },
    getHeight () {
      if (window.innerWidth > 800) {
        this.$router.push('/product/productYykffw')
      }
    }
  },
  created () {
    // 监听可视窗口宽度
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  }
}
</script>

<style scoped>
* {

  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #222;

}
img {
   object-fit: cover;
}
.container{
  width: 100%;
}
.notScroll{
  height: 0rem;
  overflow: hidden;
}
.moreBtn{
  width: 6rem;
  height: 2rem;
  border-radius: 1.875rem;
  border: 1px solid rgba(211, 218, 228, 0.5);
  line-height: 2rem;
  color: #444444;
  font-size: .875rem;
  background-color: #fff;
}
.h2Desc{
  font-size: 1.375rem;
  color: #222;
  margin-top: 2.5rem;
}
.spanDesc{
  display: inline-block;
  background-color: #D70C0C;
  width: 1.875rem;
  height: .25rem;
  border-radius: .1875rem;
  margin-top: 1.25rem;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.showBtn{
  width: 8.75rem;
  height: 2.5rem;
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  border-radius: 2.125rem;
  border: 0;
  line-height: 2.5rem;
  font-size: .875rem;
  color: #fff;
}
.contentBgColor{
  width: 100%;
    padding: 0 1.25rem;
box-sizing: border-box;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}

.moTitle{
  position: relative;
  width: 100%;
  height: 15rem;
  background: url("~@/assets/product/ProductYykffw/banner.png") center no-repeat;
  padding: 0 2.5rem;
  background-size: cover;
  object-fit: cover;

  color: #fff;
  text-align: center;
    display: flex;
  flex-direction: column;
  justify-content: center;
}
.smartDTContainer{
  padding-top: 2.75rem;
}

.ptDescContent{
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.15);
  border-radius: 10px;
  margin-top: 20px;
}
.ptDescContentText{
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  line-height: 26px;
  text-shadow: 0px 8px 24px rgba(0,0,0,0.15);
  padding: 20px;
  box-sizing: border-box;
}

.moValueContent{
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
}
.moValueItem{
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 8px;
  margin-bottom: 20px;
  border: 1px solid rgba(220,223,230,.5);
  box-sizing: border-box;
}
.moVlaueImg{
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.moValueTitle{
  font-size: 16px;
  font-weight: 550;
  color: #222222;
  margin-bottom: 10px;
}
.moValueText{
  font-size: 14px;
  font-weight: 400;
  color: #222222;
  line-height: 24px;

}
</style>
